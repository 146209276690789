<template>
  <div>
    <b-row class="d-flex align-items-center mb-2">
      <b-col>
        <b-form-group
          label="Ürün Kodu, Ürün Adı..."
          label-for="keyword"
        >
          <b-form-input
            id="keyword"
            v-model="productFilter.keyword"
            placeholder="Ürün Kodu"
            @keydown.enter="searchData"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Ürün Grubu"
          label-for="id_product_groups"
        >
          <v-select
            id="id_product_groups"
            v-model="productFilter.id_product_groups"
            class="bg-white rounded"
            label="title"
            placeholder="Ürün Grubu"
            :reduce="item => item.id"
            :options="productGroups"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="auto"
      >
        <b-button
          style="margin-top: 4px"
          variant="primary"
          @click="searchData"
        >
          <FeatherIcon icon="SearchIcon" /> Ara
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'ProductSearch',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
  },
  props: {
    searchData: {
      type: Function,
      required: true,
    },
  },
  computed: {
    productFilter() {
      return this.$store.getters['catalogs/productFilter']
    },
    productGroups() {
      return this.$store.getters['productGroups/dataList']
    },
  },
}
</script>
