<template>
  <div>
    <b-card title="Katalog Bilgisi">
      <b-row>
        <b-col
          cols="12"
        >
          <catalog-types />
        </b-col>
        <b-col
          cols="12"
        >
          <catalog-title />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <expiry-date />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <catalog-status />
        </b-col>
        <b-col cols="12">
          <catalog-content />
        </b-col>
        <b-col cols="12">
          <notes />
        </b-col>
        <b-col cols="12">
          <is-private />
        </b-col>
      </b-row>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Ürünler</b-card-title>
        <b-button
          variant="primary"
          size="sm"
          @click="openModal"
        >
          <FeatherIcon icon="PlusIcon" />
          Ürün Ekle
        </b-button>
      </b-card-header>
      <b-card-body>
        <products />
        <product-modal />
      </b-card-body>
    </b-card>
    <save-button
      :save-action="submitForm"
      :is-disabled="submitStatus"
    />
  </div>
</template>
<script>
import {
  BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody, BButton,
} from 'bootstrap-vue'
import CatalogTitle from '@/views/Admin/Catalogs/elements/Title.vue'
import ExpiryDate from '@/views/Admin/Catalogs/elements/ExpiryDate.vue'
import CatalogStatus from '@/views/Admin/Catalogs/elements/CatalogStatus.vue'
import CatalogTypes from '@/views/Admin/Catalogs/elements/CatalogTypes.vue'
import CatalogContent from '@/views/Admin/Catalogs/elements/Content.vue'
import Notes from '@/views/Admin/Catalogs/elements/Notes.vue'
import Products from '@/views/Admin/Catalogs/elements/Products.vue'
import SaveButton from '@/layouts/components/common/SaveButton.vue'
import ProductModal from '@/views/Admin/Catalogs/elements/ProductModal.vue'
import IsPrivate from '@/views/Admin/Catalogs/elements/IsPrivate.vue'

export default {
  name: 'CatalogForm',
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    CatalogTitle,
    ExpiryDate,
    CatalogStatus,
    CatalogTypes,
    CatalogContent,
    Notes,
    Products,
    SaveButton,
    ProductModal,
    IsPrivate,
  },
  props: {
    submitForm: {
      type: Function,
      required: true,
    },
    submitStatus: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    dataItem() {
      return this.$store.getters['catalogs/dataItem']
    },
    productFilter() {
      return this.$store.getters['catalogs/productFilter']
    },
  },
  methods: {
    openModal() {
      this.productFilter.modalStatus = true
    },
  },
}
</script>
