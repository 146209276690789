<template>
  <b-modal
    v-model="modalStatus.status"
    centered
    size="lg"
    hide-footer
    title="Müşteri Kartı"
  >
    <b-input-group>
      <b-form-input
        v-model="filterParams.keyword"
        placeholder="Müşteri Arama..."
        size="lg"
        @keydown.enter="customerSearch"
      />
      <b-input-group-append>
        <b-button
          variant="primary"
          @click="customerSearch"
        >
          <FeatherIcon icon="SearchIcon" />
        </b-button>
      </b-input-group-append>
    </b-input-group>
    <b-list-group class="mt-2">
      <customer-item
        v-for="item in customers"
        :key="item.id"
        :data-item="item"
        :add-customer="addCustomer"
        :remove-customer="removeCustomer"
      />
    </b-list-group>
  </b-modal>
</template>
<script>
import {
  BModal, BFormInput, BListGroup, BInputGroup, BInputGroupAppend, BButton,
} from 'bootstrap-vue'
import CustomerItem from '@/views/Admin/Catalogs/elements/CatalogPrivates/CustomerItem.vue'

export default {
  name: 'CustomersModal',
  components: {
    BModal,
    BFormInput,
    BListGroup,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    CustomerItem,
  },
  props: {
    addCustomer: {
      type: Function,
      required: true,
    },
    removeCustomer: {
      type: Function,
      required: true,
    },
  },
  computed: {
    customers() {
      return this.$store.getters['customers/dataList']
    },
    filterParams() {
      return this.$store.getters['catalogPrivates/filterParams']
    },
    dataList() {
      return this.$store.getters['catalogPrivates/dataList']
    },
    modalStatus() {
      return this.$store.getters['catalogPrivates/modalStatus']
    },
  },
  methods: {
    customerSearch() {
      if (this.filterParams.keyword) {
        this.$store.dispatch('customers/getDataList', {
          select: [
            'customers.id       AS id',
            'customers.company  AS company',
            'countries.title    AS country',
            'cities.title       AS city',
          ],
          or_like: {
            'customers.company': this.filterParams.keyword,
            'customers.phone': this.filterParams.keyword,
            'customers.tax_number': this.filterParams.keyword,
          },
          limit: 20,
          start: 0,
        })
      }
    },
  },
}
</script>
