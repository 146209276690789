<template>
  <b-list-group-item>
    <div class="d-flex justify-content-between align-items-center">
      <div class="font-weight-bold text-primary">
        <div>
          {{ dataItem.company }}
        </div>
        <div class="text-muted">
          {{ dataItem.country }}, {{ dataItem.city }}
        </div>
      </div>
      <div>
        <b-button
          v-if="!catalogs.private_customers.includes(dataItem.id)"
          variant="success"
          class="width-100"
          @click="addCustomer(dataItem)"
        >
          <FeatherIcon icon="PlusCircleIcon" />
          Ekle
        </b-button>
        <b-button
          v-else
          variant="danger"
          class="width-100"
          @click="removeCustomer(dataItem.id)"
        >
          <FeatherIcon icon="MinusCircleIcon" />
          Çıkar
        </b-button>
      </div>
    </div>
  </b-list-group-item>
</template>
<script>
import { BButton, BListGroupItem } from 'bootstrap-vue'

export default {
  name: 'CustomerItem',
  components: {
    BButton, BListGroupItem,
  },
  props: {
    dataItem: {
      type: Object,
      required: true,
    },
    addCustomer: {
      type: Function,
      required: true,
    },
    removeCustomer: {
      type: Function,
      required: true,
    },
  },
  computed: {
    catalogs() {
      return this.$store.getters['catalogs/dataItem']
    },
  },
}
</script>
