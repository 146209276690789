<template>
  <b-modal
    v-model="productFilter.modalStatus"
    size="xl"
    title="Ürün Seçimi"
    no-close-on-esc
    no-close-on-backdrop
    scrollable
  >
    <template #modal-header="{ close }">
      <div class="w-100">
        <b-button
          class="position-absolute zindex-4 position-top-0 position-right-0 mt-n1 mr-n1 btn-icon"
          variant="primary"
          pill
          @click="close"
        >
          <FeatherIcon icon="XIcon" />
        </b-button>
        <product-search
          class="mt-2"
          :search-data="searchData"
        />
      </div>
    </template>
    <div>
      <b-table
        responsive="sm"
        :fields="fields"
        :items="dataList"
        striped
        small
      >
        <template #cell(pcode)="data">
          <div class="d-flex align-items-center">
            <div class="width-200">
              <b-img-lazy
                v-if="data.item.image"
                :src=" baseURL + '/media/products/' + data.item.image"
                fluid
                class="rounded"
              />
              <empty-image v-else />
            </div>
            <div class="ml-1">
              <div class="font-weight-bolder text-primary font-medium-2">
                {{ data.item.pcode }}
              </div>
              <div class="font-weight-bolder text-secondary mb-1 border-bottom pb-1">
                {{ data.item.title }}
              </div>
              <div class="font-small-3">
                <strong>Ürün Grubu:</strong> <span class="text-warning"> {{ data.item.product_group }}</span>
              </div>
            </div>
          </div>
        </template>
        <template #cell(control)="data">
          <div class="text-right">
            <b-button
              variant="primary"
              pill
              :disabled="dataItem.id_products.includes(data.item.id)"
              @click="addProduct(data.item)"
            >
              <FeatherIcon icon="CheckCircleIcon" /> Ekle
            </b-button>
          </div>
        </template>
      </b-table>
    </div>
    <template #modal-footer>
      <div class="w-100 d-flex align-items-center justify-content-between">
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCounts"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
        <div class="text-muted">
          Toplam {{ dataCounts }} adet ürün bulundu.
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal, BButton, BTable, BPagination, BImgLazy,
} from 'bootstrap-vue'
import ProductSearch from '@/views/Admin/Catalogs/elements/ProductModal/ProductSearch.vue'
import EmptyImage from '@/layouts/components/common/EmptyImage.vue'

const tableName = 'products'
export default {
  name: 'ProductModal',
  components: {
    BModal,
    BButton,
    BTable,
    BPagination,
    BImgLazy,
    ProductSearch,
    EmptyImage,
  },
  data() {
    return {
      baseURL: this.$store.state.app.baseURL,
      currentPage: 1,
      perPage: this.$store.state.app.perPage,
      fields: [
        {
          key: 'pcode',
          label: 'ÜRÜN KODU',
        },
        {
          key: 'control',
          label: '',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id      AS id`,
          `${tableName}.pcode   AS pcode`,
          `${tableName}.title   AS title`,
          'product_groups.title AS product_group',
        ],
        limit: this.$store.state.app.perPage,
        start: 0,
        id_customers: null,
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['catalogs/dataItem']
    },
    dataList() {
      return this.$store.getters['products/dataList']
    },
    dataCounts() {
      return this.$store.getters['products/dataCounts']
    },
    productFilter() {
      return this.$store.getters['catalogs/productFilter']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * this.$store.state.app.perPage
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
    this.getProductGroups()
  },
  methods: {
    addProduct(data) {
      if (this.dataItem.id) {
        this.$store.dispatch('catalogs/addLine', {
          id_products: data.id,
          ordering: this.dataItem.items.length + 1,
          id_catalogs: this.dataItem.id,
        })
          .then(response => {
            if (response.status) {
              this.$store.dispatch('catalogs/getDataItem', this.dataItem.id)
            }
          })
      } else {
        this.dataItem.items.push({
          id: null,
          pcode: data.pcode,
          title: data.title,
          image: data.image,
          product_group: data.product_group,
          ordering: null,
          id_catalogs: null,
          id_products: data.id,
        })
        this.dataItem.id_products.push(data.id)
      }
    },
    getProductGroups() {
      this.$store.dispatch('productGroups/getDataList', {
        select: [
          'product_groups.id AS id',
          'product_groups.title AS title',
        ],
      })
    },
    getDataList() {
      this.dataQuery.id_customers = this.dataItem.id_customers
      this.dataQuery.id_catalogs = this.dataItem.id
      this.dataQuery.createCatalog = true
      this.$store.dispatch('products/getDataList', this.dataQuery)
    },
    searchData() {
      this.dataQuery.start = 0
      this.currentPage = 1
      this.dataQuery.where = {}
      if (this.productFilter.keyword) {
        this.dataQuery.or_like = {
          'products.pcode': this.productFilter.keyword,
          'products.title': this.productFilter.keyword,
        }
      } else {
        this.dataQuery.or_like = {}
      }
      if (this.productFilter.id_product_qualities) {
        this.dataQuery.where['products.id_product_qualities'] = this.productFilter.id_product_qualities
      }
      if (this.productFilter.id_product_groups) {
        this.dataQuery.where['products.id_product_groups'] = this.productFilter.id_product_groups
      }
      if (this.productFilter.width) {
        this.dataQuery.where['products.width'] = this.productFilter.width
      }
      if (this.productFilter.weight) {
        this.dataQuery.where['products.weight'] = this.productFilter.weight
      }
      this.getDataList()
    },
  },
}
</script>
