<template>
  <b-row class="d-flex align-items-center">
    <b-col
      cols="12"
      md="6"
    >
      <b-form-group
        label="Gizli Katalog"
        label-for="title"
      >
        <b-form-checkbox
          v-model="catalogs.is_private"
          switch
          value="1"
        />
      </b-form-group>
    </b-col>
    <b-col
      v-if="catalogs.is_private"
      cols="12"
      md="6"
      class="text-right"
    >
      <b-button
        variant="primary"
        @click="setModal"
      >
        <FeatherIcon icon="PlusCircleIcon" />
        Müşteri Ekle
      </b-button>
    </b-col>
    <b-col
      v-if="catalogs.is_private"
      cols="12"
    >
      <b-alert
        variant="warning"
        show
      >
        <div class="alert-body text-center">
          Gizli kataloglar sadece seçili müşterilere gösterilir.
        </div>
      </b-alert>
      <b-list-group class="mt-2">
        <customer-item
          v-for="item in dataList"
          :key="item.id"
          :data-item="item"
          :remove-customer="removeCustomer"
          :add-customer="addCustomer"
        />
      </b-list-group>
      <customers-modal
        :add-customer="addCustomer"
        :remove-customer="removeCustomer"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol,
  BFormGroup, BFormCheckbox, BListGroup, BAlert, BButton,
} from 'bootstrap-vue'
import CustomersModal from '@/views/Admin/Catalogs/elements/CustomersModal.vue'
import CustomerItem from '@/views/Admin/Catalogs/elements/CatalogPrivates/CustomerItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'Title',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BListGroup,
    BAlert,
    BButton,
    CustomersModal,
    CustomerItem,
  },
  computed: {
    dataItem() {
      return this.$store.getters['catalogPrivates/dataList']
    },
    dataList() {
      return this.$store.getters['catalogPrivates/dataList']
    },
    catalogs() {
      return this.$store.getters['catalogs/dataItem']
    },
    modalStatus() {
      return this.$store.getters['catalogPrivates/modalStatus']
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    addCustomer(data) {
      const isExisting = this.dataList.some(item => item.id === data.id)
      if (!isExisting) {
        this.dataList.push(data)
        this.catalogs.private_customers.push(data.id)
      } else {
        this.showToast({
          title: 'Uyarı!',
          icon: 'info',
          text: 'Müşteri kartı zaten listenize eklenmiş.',
          variant: 'danger',
        })
      }
    },
    removeCustomer(id) {
      const customerIndex = this.dataList.findIndex(customer => customer.id === id)
      if (customerIndex !== -1) {
        this.dataList.splice(customerIndex, 1)
      }
      const privateIndex = this.catalogs.private_customers.findIndex(item => item === id)
      if (privateIndex !== -1) {
        this.catalogs.private_customers.splice(privateIndex, 1)
      }
    },
    setModal() {
      this.modalStatus.status = true
      this.$store.commit('customers/SET_DATA_LIST', [])
    },
    getDataList() {
      if (this.$route.params.id) {
        this.$store.dispatch('catalogPrivates/getDataList', {
          select: [
            'catalog_privates.id AS id',
            'customers.company AS company',
            'countries.title AS country',
            'cities.title AS city',
          ],
          where: {
            'catalog_privates.id_catalogs': this.$route.params.id,
          },
        })
      }
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
